'use client';

import { ErrorFallback } from '@/app/components/ErrorFallback';
import { Input } from '@/app/components/Input';
import { ParsedText } from '@/app/components/ParsedText';
import { useTranslation } from '@/app/i18n/client';
import { applicationStartingPoint as APP_START_URL } from '@/lib/pageroutes';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '@/app/components/Button';
import { LeadCaptureForm } from '../LeadCaptureForm';
import persistenceService from '@/lib/utils/persistenceService';
import { STORAGE_KEY } from '@/app/contexts/global/actions';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { ReturnToJourneyCTA } from '../ReturnToJourneyCTA';
import { useUTMParams } from '@/app/components/Analytics/hooks/useUTMParams';

export const EmailCapture = ({
  forceSinglePage,
}: {
  forceSinglePage?: boolean;
}) => {
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const { t } = useTranslation();
  const router = useRouter();
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const allowReturnToJourney = partnerSettings.allowReturnToJourney;
  const utmParams = useUTMParams();

  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');

  if (!host_url) {
    return (
      <h1 className="text-error-1 font-bold text-xl">
        Error: HOST_URL is not set
      </h1>
    );
  }

  const errorAttribute = 'data-error';
  const revalidate = (e: Event) => {
    validateElement(e.target as HTMLInputElement, true);
  };

  const validateElement = (el: HTMLInputElement, inErrorState?: boolean) => {
    const elIsValid = el.checkValidity();

    if (!elIsValid) {
      el.setAttribute(errorAttribute, 'true');

      if (!inErrorState) {
        el.addEventListener('keyup', (e) => revalidate(e));
      }
    } else if (el.hasAttribute(errorAttribute)) {
      el.removeAttribute(errorAttribute);
      el.removeEventListener('keydown', revalidate);
    }

    return elIsValid;
  };

  const validateForm = (form: HTMLFormElement): boolean => {
    const formEls = form.elements;

    let valid = true;
    Array.from(formEls).forEach((el, i) => {
      const elIsValid = validateElement(el as HTMLInputElement);

      if (!elIsValid && valid) {
        valid = false;
      }
    });

    return valid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const valid = validateForm(e.currentTarget);

    if (valid) {
      let UTMTerm = utmParams.marketing_term;

      const formData = new FormData(e.currentTarget);
      persistenceService.set(STORAGE_KEY, {
        offersPayload: {
          application: {
            merchant_id: UTMTerm,
          },
          applicants: [{ email_address: formData.get('EmailCaptureInput') }],
        },
        utm_campaign: utmParams.marketing_campaign,
      });
      setEmailSent(true);
      router.push(APP_START_URL);
    }
  };

  return (
    <div>
      <div className="max-w-xl mx-auto mt-12 pb-20">
        {partnerSettings.singlePageJourney || forceSinglePage ? (
          <ErrorBoundary
            fallback={
              <ErrorFallback
                errorTitle={t('lead-capture-form-error-title')}
                errorMessage={t('lead-capture-form-error-message').replace(
                  '{contactPhoneNumber}',
                  partnerSettings.contactPhoneNumber!,
                )}
              />
            }
          >
            <LeadCaptureForm />
          </ErrorBoundary>
        ) : (
          <>
            <form
              className="col-start-4 col-span-6 "
              onSubmit={handleSubmit}
              method="POST"
              autoComplete="off"
              noValidate
            >
              <h1 className="text-header-lg-mobile lmobile:text-header-lg text-center">
                {t('go-fund-yourself-header')}
              </h1>
              <h2 className="text-4 text-core-7 text-center mt-4 mb-8">
                {t('go-fund-yourself-subheader')}
              </h2>
              <>
                <Input
                  type="email"
                  id="EmailCaptureInput"
                  name="EmailCaptureInput"
                  styling=""
                  placeholder={t('go-fund-yourself-email-placeholder')}
                  required
                  errorMessage={t('go-fund-yourself-email-error-message')}
                />
              </>
              <div className="mt-8">
                <Button
                  includeArrow={true}
                  id="GoFundYourself"
                  mixpanelButtonText={t('go-fund-yourself-button')}
                  type="submit"
                  isLoading={emailSent}
                >
                  {t('go-fund-yourself-button')}
                </Button>
              </div>
            </form>
            {allowReturnToJourney && (
              <ReturnToJourneyCTA id="ReturnToJourney-GoFundYourself" />
            )}
            <div className="mt-6 text-center text-core-7 text-sm mx-8 [&>p>a]:whitespace-nowrap [&>p>a]:font-bold [&>p>a]:underline ">
              <ParsedText
                htmlString={t('go-fund-yourself-consent', {
                  buttonLabel: t('go-fund-yourself-button'),
                }).replace(
                  '{privacy-policy-url}',
                  partnerSettings['privacy-policy-url'],
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
